import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import queryString from 'query-string';
import React, { FC } from 'react';

import { useGetRewardDetailPageLink } from '@/bits/links/useLink';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  NextIcon,
  PlusIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import { PlayerRewardCreateForm } from '@/forms';
import { PlayerRewardStatus, RewardTypeEnum } from '@/globalTypes';
import { SanityPlayerRewardsBlockFragment } from './__generated__/SanityPlayerRewardsBlockFragment';
import usePlayerRewards from './usePlayerRewards';

export const Fragment = graphql`
  fragment SanityPlayerRewardsBlockFragment on SanityPlayerRewardsBlock {
    title {
      ...LocaleString
    }
  }
`;

const PlayerRewardsBlock: FC<{
  block: SanityPlayerRewardsBlockFragment;
}> = ({ block }) => {
  const params = useParams();
  const { t } = useTranslate();

  const getRewardDetailLink = useGetRewardDetailPageLink();

  const {
    player,
    playerRewards,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = usePlayerRewards(params.playerId);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'playerRewards',
    data: playerRewards,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.dateTimeValue({
        field: 'createdAt',
        title: 'Created Date',
        getValue: ({ row }) => row.reward?.createdAt,
      }),
      s.stringValue({
        field: 'name',
        title: 'Reward Name',
        getValue: ({ row }) => row.reward?.name,
        linkTo: ({ row }) =>
          getRewardDetailLink(
            `${row.reward?.id}?${queryString.stringify({
              tabIndex: 0,
            })}`,
          ) || '',
      }),
      s.stringValue({
        field: 'createdBy',
        title: 'Created By',
        getValue: ({ row }) =>
          row.reward?.createdBy?.firstName +
          ' ' +
          row.reward?.createdBy?.lastName,
      }),
      s.enumValue({
        field: 'rewardType',
        title: 'Type',
        e: RewardTypeEnum,
        getValue: ({ row }) => row.reward?.rewardType.name,
      }),
      s.dateTimeValue({
        field: 'startsAt',
        title: 'Start At',
        getValue: ({ row }) => row.reward?.startsAt,
      }),
      s.dateTimeValue({
        field: 'endsAt',
        title: 'Ends At',
        getValue: ({ row }) => row.reward?.endsAt,
      }),
      s.enumValue({
        field: 'status',
        title: 'Status',
        filterField: 'rewardStatus',
        e: PlayerRewardStatus,
      }),
    ],
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          <ControlledModal
            content={
              player ? <PlayerRewardCreateForm playerId={player.id} /> : null
            }
          >
            <CardOptionsButton>
              <PlusIcon />
            </CardOptionsButton>
          </ControlledModal>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerRewardsBlock;
