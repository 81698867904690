import { BooleanParam, StringParam, withDefault } from 'use-query-params';

import { PlayerRewardStatusParam } from '@/utils/query/params';

export const queryParams = {
  playerId: StringParam,
  desc: withDefault(BooleanParam, true),
  rewardStatus: PlayerRewardStatusParam,
  before: StringParam,
  after: StringParam,
};
